@import url('https://fonts.googleapis.com/css2?family=Oswald&display=swap');

html {
  scroll-behavior: smooth;
}
body{
  font-family: 'Oswald', sans-serif;
  background-image: url('https://img.freepik.com/free-vector/watercolor-mountains-background_23-2149238187.jpg?size=626&ext=jpg&ga=GA1.1.1547174089.1701251264&semt=ais');
  

}

a{
  text-decoration: none;
  color: white;
}

.header-container{
  height: 9rem;
  position: fixed;
  width: 100%;
}
.nav-ul{
  margin-top: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10rem;
  height: 7rem;
  list-style-type: none;
  color: white;
  font-size: 2rem;
  border-bottom: 10px black;
  box-shadow: 0 20px 10px rgba(0, 0, 0, 0.364);
}

.navbar {
  background: linear-gradient(rgb(93, 49, 20), rgb(61, 31, 10));
  background-blend-mode: 80%;

}

.navbar.colorChange {
  opacity: 5%;
}
.navbar.colorChange:hover{
  opacity: 80%;
  
}
.header-logo{
  width: 17rem;
  margin-bottom: 1.4rem;
}
.om-container{
  padding-top: 9rem;
  background-image: url('./dashi-bg.jpeg');
  background-size: 100%;
  background-repeat: no-repeat;
  color: rgb(255, 239, 239);
  display: flex;
  flex-direction: row;
}
.information{
 margin-top: 5rem;
 width: 30rem;
 margin-right: 30rem;
 margin-bottom: 4rem;
}
.location{
  margin-top: 5rem;
  width: 100rem;
  margin-left: 20rem;
  padding: 20px 50px ;
}
.location > p {
 width: 40rem;
}
.meny-container{
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 2rem;
}
.meny-container > img{
  margin-top: 2rem;
  
}
.order-container{
  max-height: 50rem;
  background-image: url('./dashi-mat.jpeg');
  background-size: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  color: bisque;
  font-size: 40px;
  text-shadow: 5px 4px 2px rgb(93, 49, 20);
}



.footer-container{
  /* background: linear-gradient(rgb(93, 49, 20), rgb(61, 31, 10)); */
  background-image: url('https://img.freepik.com/free-vector/watercolor-mountains-background_23-2149238187.jpg?size=626&ext=jpg&ga=GA1.1.1547174089.1701251264&semt=ais');
  background-position-y: bottom;
  color: rgb(255, 246, 238);
  font-weight: 800;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  
}
.logo {
  width: 3rem;
  margin-left: 10px;
}
.ramen{
  margin-bottom: -3rem;
}

@media  screen and (max-width:780px) {
  body{
    padding: 0;
    display: flex;
    margin: 0;
  }
  .header-logo{
    height: 10rem;
    width: auto;
  }
  
  .header-container{
    max-width: 780px;
    width: 100%;
    margin: auto;
  }
  .nav-ul{
    margin: 0;
    padding: 0;
    gap: 10%;
  }
  .om-container {
    max-width: 780px;
    width: 100%;
    height: auto;
  }
  .location, .information{
    font-size: 10px;
    margin: 0;
    padding:  0 0  5px 10px;
  }
  .order-container{
    max-width: 780px;
    width: 100%;
    font-size: 16px; 
    height: auto; 
  }
  .ramen{
    height: 10rem;
  }
  .meny-container{
    margin: 0;
    width: 100%;
    max-width: 780px;
  }
}